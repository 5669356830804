@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined);
:root {
    --color-primary: #1d3495;
    --color-primary-light-1: #1d34951c;
    --color-primary-light-2: #4c93e3;
    --color-primary-light-3: #0026c8;
    --color-primary-dark: #02197a;
    --color-primary-dark-1: #1d3495c0;
    --color-primary-dark-2: #176ac7;
    --color-primary-dark-3: #001c96;
    --color-secondary: #31aeac;
    --color-secondary-light-1: #31aeac33;
    --color-secondary-light-2: #03828035;
    --color-secondary-dark: #097877;
    --color-secondary-dark-1: #31aeacf8;
    --color-secondary-dark-2: #038280ca;

    --color-input-border-default: #e4e4e4;
    --color-input-border-focus: var(--color-primary);
    --color-input-label: #74798c;
    --color-input-place-holder: #99a3ac;
    --color-input-error: #f53a6b;

    --box-shadow-input-focus: 0 0 1px 0 rgba(178, 209, 255, 0.49);

    --color-scrollbar: #99a3ac;

    --color-black: #000000;
    --color-white: #ffffff;
    --color-error: #f53a6b;

    --dimen-input-border-radius: 0.25rem;
    --dimen-input-padding: 0.475rem 0.85rem;
    --dimen-button-padding: 0.538rem 1.25rem;

    --font-size-default: 12px;
    --input-size: 0.75rem;

    --transition-button: all 200ms ease-in-out;
}

/* Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

body {
    font-size: 12px;
    font-size: var(--font-size-default);
}

/* Buttons */
._2Mmd4 {
    display: inline-block;
    transition: all 200ms ease-in-out;
}

._2Mmd4:focus {
    outline: 0;
}

._2Mmd4 > main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Anchors */
a,
a:hover {
    text-decoration: none;
}

